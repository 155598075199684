<template>
  <v-sheet>
    <v-card-text class="mt-5">
      <div class="d-flex justify-end">
        <v-btn :to="{name: 'CreateShop'}" color="secondary">
          {{ shops.length ? 'Create New Shop' : 'Create Shop' }}
        </v-btn>
      </div>
    </v-card-text>
    <v-divider></v-divider>

    <!--shop list-->
    <v-simple-table v-if="shops.length" dense>
      <template v-slot:default>
        <thead>
        <tr>
          <th>Requested</th>
          <th>Shop Name</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(shop, index) in shops" :key="'orders'+index">
          <td>{{ shop.created_at | date }}</td>
          <td>{{ shop.name }}</td>
          <td v-html="statusBadge(shop.status)"></td>
          <td>
            <v-btn
              v-if="shop.status === 'pending'"
              color="error"
              @click="handleCancellation(false, shop)"
            >
              Cancel
            </v-btn>
            <div v-if="shop.status === 'active'">
              <v-btn :href="adminUrl" class="mr-3" color="primary" target="_blank">Manage</v-btn>
              <v-btn :to="{name: 'Vendor', params: {slug: shop.slug}}" color="secondary" outlined>Visit shop</v-btn>
            </div>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!--no shop message-->
    <v-card-text v-else class="d-inline-flex justify-space-around">
      <v-alert outlined type="info">
        You have no shop or pending shop request
      </v-alert>
    </v-card-text>

    <!--Shop request cancel popup-->
    <v-dialog v-model="showCancelDialog" max-width="500">
      <v-card v-if="showCancelDialog">
        <v-card-title class="grey black--text">Are you sure</v-card-title>
        <v-card-text class="pt-5">
          <p>Are you sure you want to cancel <strong>{{ selectedShop.name }}</strong> Opening request?</p>
          <p class="error--text">this operation cannot undo</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <div class="d-flex justify-end">
            <v-btn outlined @click="showCancelDialog = false">Cancel</v-btn>
            <v-btn
              :loading="formSubmitting"
              class="ml-5"
              color="primary"
              @click="handleCancellation(true)"
            >
              Submit
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cancelShop, userShops } from '@/api/user';
import Toast from '@/utils/Toast';
import { BASE_ADMIN_URL } from '@/utils/appConstants';

export default {
  name: 'shopRequests',
  data: () => ({
    selectedShop: null,
    showCancelDialog: false,
    adminUrl: BASE_ADMIN_URL,
    formSubmitting: false
  }),
  computed: {
    ...mapGetters({
      shops: 'user/userShop',
      user: 'user/data'
    })
  },
  methods: {
    ...mapActions({
      shopData: 'user/userShop'
    }),
    async handleCancellation(confirmed = false, shop) {

      if (!confirmed) {
        this.selectedShop = shop;
        this.showCancelDialog = true;
        return;
      }
      this.formSubmitting = true;
      try {
        let response = await cancelShop({ id: this.selectedShop.id });
        Toast.success(response.data.message || 'Shop Request Cancelled');
        this.showCancelDialog = false;
        this.formSubmitting = false;
        await this.getShops();
      } catch (e) {
        Toast.error(e.message || 'There is an error. please try again');
      }
      this.formSubmitting = false;
    },
    async getShops() {
      let { data } = await userShops();
      await this.shopData(data.data);
    }
  },
  async mounted() {
    await this.getShops();
  }
};
</script>

<style scoped>

</style>
